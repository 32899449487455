import './transmit.css';
import { Grid } from 'semantic-ui-react';
// import transmitData from '../../assets/transmitData.svg';
import React from 'react';
import Icons from '../transmitAnimation/transitAnimation';



function Transmit() {
    return (
        <div className="transmitComponent"> 
             <div className="transmitContainer">
                <Grid reversed='computer' centered container className='showborder' columns={2}>
                    <Grid.Row className='showborder' stretched>
                        <Grid.Column verticalAlign='middle' sclassName='showborder' mobile={16} tablet={10} computer={8}>
                            <div className='transmitHeading'>Transmit Data</div>
                            <div className='transmitText'>Between storage platforms with ease. Create and control unstructured data pipelines between storage platforms for data orchestration</div>
                        </Grid.Column>
                        <Grid.Column className='showborder' mobile={16} tablet={10} computer={8}>
                            <Icons className='transmitAnimation' />
                        </Grid.Column>
                    </Grid.Row>
                
                </Grid>
            </div>
        </div>
    );
}

export default Transmit;
