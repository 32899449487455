import './navbar.css';
import logo from '../../assets/bifrustLogo.svg';
import { Button, Dropdown, Grid, Icon, Input, Message } from 'semantic-ui-react';
import React, { useState, useEffect} from 'react';
import authUser from '../auth/authUser';
import axios from 'axios';

function Navbar() {
    const [scrollState, setScrollState] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false); // New state for dropdown
    const [isRegistering, setIsRegistering] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false); // State for Terms and Conditions checkbox
    const [isEmailReset, setIsEmailReset] = useState(false); // State to show email reset options
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const { http } = authUser();

    useEffect(() => {
        // Get the current URL's query string
        const urlParams = new URLSearchParams(window.location.search);
        console.log('URL params:', urlParams);
        // Check if the 'r' query parameter exists and set isRegistering accordingly
        if (urlParams.has('r')) {
          setIsRegistering(true);
          setDropdownOpen(true);
        }
        else if (urlParams.has('p')) {
            setIsPasswordReset(true);
            setDropdownOpen(true);
          }
        else if (urlParams.has('l')) {
            setDropdownOpen(true);
          }
      }, []);

    const changeNavbarBG = () => {
        const scrollValue = document.documentElement.scrollTop;
        setScrollState(scrollValue > 60);
    };

    window.addEventListener('scroll', changeNavbarBG);

    const handleDropdownToggle = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && (email && password || (isRegistering && name && email && password && confirmPassword && acceptTerms))) {
            handleSubmit(event);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        // Validation for registration
        if (isRegistering) {
            if (name === '' || email === '' || password === '' || confirmPassword === '') {
                setErrorMsg('All fields must be filled out.');
                return;
            }
            if (!acceptTerms) {
                setErrorMsg('You must accept the terms and conditions.');
                return;
            }

            if (password !== confirmPassword) {
                setErrorMsg('Passwords do not match.');
                return;
            }

            setErrorMsg('');
            // Registration API call
            http.post('https://bifrust.com/api/register', { name, email, password })
                .then((res) => {
                    console.log('Registration response:', res);
                    if (!res.error) {
                        setSuccessMsg('Registration successful! You can now log in.');
                        setErrorMsg('');
                        setIsRegistering(false);
                        setIsEmailReset(true);
                        setToken(res.data.token);
                    } else {
                        setErrorMsg('Registration failed. Please try again.');
                    }
                })
                .catch((error) => {
                    console.error('Registration error:', error);
                    setErrorMsg('An error occurred during registration.');
                });
        } else {
            // Login Logic
            if (email === '' || password === '') {
                setErrorMsg('Email and Password could not be null.');
                return;
            }
            setErrorMsg('');
            http.post('https://bifrust.com/api/login', { email, password })
                .then((res) => {
                    console.log('Login response1:', res);
                    if (!res.error) {
                        // Successful login logic here...
                        if (res.data.user && res.data.user.email_verified_at != null) {
                            // Assuming successful login handling here...
                            const form = document.createElement('form');
                            form.method = 'POST';
                            form.action = 'https://bifrust.com/login'; 

                            const emailField = document.createElement('input');
                            emailField.type = 'hidden';
                            emailField.name = 'email';
                            emailField.value = email;  
                            form.appendChild(emailField);

                            const passwordField = document.createElement('input');
                            passwordField.type = 'hidden';
                            passwordField.name = 'password';
                            passwordField.value = password;  
                            form.appendChild(passwordField);

                            document.body.appendChild(form);
                            form.submit(); 
                        }
                        else{
                            setIsEmailReset(true);
                            setToken(res.data.token);
                            setErrorMsg('Please verify your email before logging in.');
                        }
                    } else {
                        setErrorMsg('These credentials do not match our records.');
                    }
                })
                .catch((error) => {
                    console.error('Login error:', error);
                    setErrorMsg('These credentials do not match our records.');
                });
        }
    };

    const handleNewAccountClick = () => {
        setErrorMsg('');
        setIsEmailReset(false)
        setIsRegistering(true);
    };

    const handleTermsChange = () => {
        setAcceptTerms(!acceptTerms);
    };

    const handleResendEmail = async () => {
        try {
            setSuccessMsg('');
            setErrorMsg('');
            const response = await axios.post('https://bifrust.com/api/email/resend', {email}, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the Bearer token
                    'Content-Type': 'application/json'
                }
            });
            console.log('Verification email sent:', response);
            setSuccessMsg('Verification email sent.Check your inbox');
        } catch (error) {
            setErrorMsg('Error sending verification email.');
            console.error('Error sending verification email:', error);
        }
    };

    const handleResetPassword = async () => {
        try {
            setSuccessMsg('');
            setErrorMsg('');
            if (email === '') {
                setErrorMsg('Email must be filled out.');
                return;
            }
            const response = await axios.post('https://bifrust.com/api/password/email', {email}, {
            });
            console.log('reset password:', response);
            setSuccessMsg('Reset password email sent.Check your inbox');
        } catch (error) {
            setErrorMsg('Error sending reset password email.');
            console.error('Error sending reset password email:', error);
        }
    };


    return (
        <div className="navbar">
            <header>
                <div className="nav">
                    <div className={scrollState ? "navScroll" : "nav"}>
                        <Grid verticalAlign="middle" centered columns={3}>
                            <Grid.Row className={scrollState ? "rowScroll" : ""} stretched>
                                <Grid.Column width={2}>
                                    <div className="navLogo">
                                        <img className={scrollState ? "navLogoImgScroll" : "navLogo"} src={logo} alt="logo" />
                                    </div>
                                </Grid.Column>
                                <Grid.Column verticalAlign="middle" width={8}>
                                    <ul className={scrollState ? "navULScroll" : ""}>
                                        <a href="#splashBottomSection"><li>Features</li></a>
                                        <li>Privacy</li>
                                        <a href="https://docs.bifrust.com"><li>Docs</li></a>
                                    </ul>
                                </Grid.Column>
                                <Grid.Column centered width={2}>
                                    <div className={scrollState ? "loginButtonScroll" : "loginButton"}>
                                        <Dropdown
                                            direction="left"
                                            icon="user"
                                            open={dropdownOpen} // Control dropdown open state
                                            onClick={handleDropdownToggle} // Toggle dropdown
                                        >
                                            <Dropdown.Menu className="loginDropDown" onClick={(e) => e.stopPropagation()}>
                                                <form onSubmit={handleSubmit} id="login-form" className="loginForm">
                                                    {isRegistering ? (
                                                            <>
                                                                <Input
                                                                    placeholder="Name"
                                                                    id="name"
                                                                    type="text"
                                                                    icon="user"
                                                                    onChange={(event) => setName(event.target.value)}
                                                                    name="name"
                                                                    onKeyDown={handleKeyPress}
                                                                />
                                                                <Input
                                                                    placeholder="Email"
                                                                    id="email"
                                                                    type="text"
                                                                    icon="mail"
                                                                    onChange={(event) => setEmail(event.target.value)}
                                                                    name="email"
                                                                    onKeyDown={handleKeyPress}
                                                                />
                                                                <Input
                                                                    placeholder="Password"
                                                                    id="password"
                                                                    type="password"
                                                                    icon="lock"
                                                                    onChange={(event) => setPassword(event.target.value)}
                                                                    name="password"
                                                                    onKeyDown={handleKeyPress}
                                                                />
                                                                <Input
                                                                    placeholder="Confirm Password"
                                                                    id="confirmPassword"
                                                                    type="password"
                                                                    icon="lock"
                                                                    onChange={(event) => setConfirmPassword(event.target.value)}
                                                                    name="confirmPassword"
                                                                    onKeyDown={handleKeyPress}
                                                                />
                                                                <div className="termsCheckbox" style={{ paddingLeft: "8px", paddingTop: "15px", paddingBottom: "10px" }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        id="terms"
                                                                        checked={acceptTerms}
                                                                        onChange={handleTermsChange}
                                                                    />
                                                                    <label htmlFor="terms" style={{ paddingLeft: "3px" }}>
                                                                        I accept the <a href="https://bifrust.com/tos" target="_blank">Terms and Conditions</a>
                                                                    </label>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            !isEmailReset && !isPasswordReset && <>
                                                                <Input
                                                                    placeholder="Email"
                                                                    id="email"
                                                                    type="text"
                                                                    icon="user"
                                                                    onChange={(event) => setEmail(event.target.value)}
                                                                    name="email"
                                                                    onKeyDown={handleKeyPress}
                                                                />
                                                                <Input
                                                                    placeholder="Password"
                                                                    icon="key"
                                                                    id="password"
                                                                    type="password"
                                                                    name="password"
                                                                    onKeyDown={handleKeyPress}
                                                                    onChange={(event) => setPassword(event.target.value)}
                                                                />
                                                            </>
                                                        )}

                                                        {/* Center aligned error and success messages */}
                                                        {!isEmailReset && !isPasswordReset && errorMsg && <p style={{textAlign: 'center'}} className="errorMsg">{errorMsg}</p>}
                                                        {!isEmailReset && !isPasswordReset && successMsg && <p style={{textAlign: 'center'}} className="successMsg">{successMsg}</p>}

                                                        {!isEmailReset && !isPasswordReset && <Button className="submitButton" type="submit">
                                                            {isRegistering ? 'Register' : 'Login'}
                                                        </Button>
                                                        }
                                                </form>
                                                {/* Reset Email Section */}
                                                {isEmailReset && (
                                                    <Dropdown.Item>
                                                        <p  style={{ color: "#ED3030" }}>
                                                            Before proceeding, please check your email for a verification link.
                                                            <br />
                                                            If you did not receive the email, click the button below to resend.
                                                        </p>
                                                        <div className="emailResetSection">
                                                            <Input
                                                                value={email}
                                                                readOnly
                                                                type="text"
                                                                icon="mail"
                                                                onKeyDown={handleKeyPress}
                                                                fluid
                                                            />
                                                            {/* Center aligned error and success messages */}
                                                            {isEmailReset && errorMsg && <p style={{textAlign: 'center'}} className="errorMsg">{errorMsg}</p>}
                                                            {isEmailReset && successMsg && <p style={{textAlign: 'center'}} className="successMsg">{successMsg}</p>}
                                                            <Button className="submitButton"
                                                                onClick={handleResendEmail}
                                                            >
                                                                Resend Email
                                                            </Button>
                                                        </div>
                                                    </Dropdown.Item>
                                                )}

                                                {/* Reset Email Section */}
                                                {isPasswordReset && (
                                                    <Dropdown.Item>
                                                        <div className="emailResetSection">
                                                            <Input
                                                                value={email}
                                                                type="text"
                                                                icon="mail"
                                                                onKeyDown={handleKeyPress}
                                                                placeholder="Email"
                                                                onChange={(event) => setEmail(event.target.value)}
                                                                fluid
                                                            />
                                                            {/* Center aligned error and success messages */}
                                                            {(isPasswordReset || isEmailReset) && errorMsg && <p style={{textAlign: 'center', paddingTop:'10px'}} className="errorMsg">{errorMsg}</p>}
                                                            {(isPasswordReset || isEmailReset) && successMsg && <p style={{textAlign: 'center', paddingTop:'10px'}} className="successMsg">{successMsg}</p>}
                                                            <Button className="submitButton"
                                                                onClick={handleResetPassword}
                                                            >
                                                                Recover
                                                            </Button>
                                                        </div>
                                                    </Dropdown.Item>
                                                )}

                                                <div className="bottomLinks"  style={{ display: 'flex' }}>
                                                    {isRegistering && !isPasswordReset ? (
                                                        <>
                                                            <div style={{display: 'flex', justifyContent: 'flex-end', padding: '10px 0', width: '100%'}}>
                                                                <a
                                                                    style={{ color: "#ED3030", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                    setIsRegistering(false);
                                                                    setErrorMsg('');
                                                                    setSuccessMsg('');
                                                                    setIsEmailReset(false);
                                                                    }}
                                                                >
                                                                    Back
                                                                </a>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        !isPasswordReset ? (
                                                        <>
                                                            <div>
                                                                <a style={{ color: "#ED3030", cursor: "pointer" }} 
                                                                onClick={() => { setIsPasswordReset(true);setIsRegistering(false); setErrorMsg(''); setSuccessMsg('');setIsEmailReset(false) }}
                                                                >
                                                                    Forgot password?
                                                                </a>
                                                            </div>
                                                            {!isEmailReset ? (
                                                                <>
                                                                    <div>
                                                                        <a
                                                                            style={{ color: "#ED3030", cursor: "pointer" }}
                                                                            onClick={handleNewAccountClick}
                                                                        >
                                                                            New Account
                                                                        </a>
                                                                    </div>
                                                                </>
                                                            ) :(
                                                                <>
                                                                    <div>
                                                                        <a
                                                                            style={{ color: "#ED3030", cursor: "pointer" }}
                                                                            onClick={() => { setIsRegistering(false); setErrorMsg(''); setSuccessMsg('');setIsEmailReset(false) }}
                                                                        >
                                                                            Login
                                                                        </a>
                                                                    </div>
                                                                </>
                                                            )}
                                                            
                                                        </>) : (
                                                            <>
                                                                
                                                                <div style={{display: 'flex', justifyContent: 'flex-end', padding: '10px 0', width: '100%'}}>
                                                                <a style={{ color: "#ED3030", cursor: "pointer" }} 
                                                                onClick={() => { setIsPasswordReset(false);setIsRegistering(false); setErrorMsg(''); setSuccessMsg('');setIsEmailReset(false) }}
                                                                >
                                                                    Back
                                                                </a>
                                                            </div>
                                                            </>
                                                        )
                                                    )}
                                                    </div>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        {/* <div className="hamburgerButton">
                                            <Button>
                                                <Button.Content visible>
                                                    <Icon name="bars" />
                                                </Button.Content>
                                            </Button>
                                        </div> */}
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Navbar;
