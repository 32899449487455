import './root.css';
import Navbar from './navbar/navbar';
import Splash from './splash/splash';
import DataInsights from './dataInsights/dataInsights';
import Connectivity from './connectivity/connectivity';
import Search from './search/search';
import Transmit from './transmit/transmit';
import Benefits from './benefits/benefits';
import Privacy from './privacy/privacy';
import Pricing from './pricing/pricing';
import Footer from './footer/footer'
import React from 'react';

function Root() {
return(
  <div className="landingWrapper">
    <Navbar />
    <Splash />
    <DataInsights />
    <Connectivity />
    <Search />
    <Transmit />
    <Benefits />
    {/* <Privacy />
    <Pricing /> */}
    <Footer />
  </div>
)
}

export default Root;